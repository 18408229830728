.page-not-found {
  display: flex;
  flex-direction: column;

  &__title {
    text-align: center;
    font-size: 30px;
    font-weight: 100;
  }
}
