.product-card {
  max-width: 330px;
  color: inherit;
  background: #FFFFFF;
  transition: ease .3s;

  &:hover {
    box-shadow: 0 0 25px 0 rgba(32, 69, 105, .2);
  }

  &__link {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  &__wrap {
    padding: 15px 25px;
  }

  &__status {
    display: block;
    font-size: 12px;
    min-height: 18px;
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 10px 0;
    height: 48px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    color: #0A2540;
  }

  &__img {
    width: 100%;
    height: 135px;
    object-fit: cover;
  }

  &__desc {
    margin-right: 5px;
    font-size: 13px;
    line-height: 24px;
    color: #204569;
    opacity: 0.5;
  }

  &__item {
    margin-bottom: 5px;
  }

  &__sep {
    margin: 15px 0;
    opacity: 0.25;
    border-top: 1px dashed #204569;
  }

  &__time {
    display: flex;
    align-items: center;
    margin-top: 25px;
    font-size: 12px;

    &--desc {
      margin-left: 10px;
      margin-right: 5px;
      color: #204569;
      opacity: 0.5;
    }
  }

  &__btn-group {
    margin: 25px 0 10px;

    &--supplier {
      display: flex;
      justify-content: space-between;

      .default-button-wrapper {
        width: 47%;
      }

      .default-button {
        min-width: auto;
        width: 100%;
      }
    }
  }
}