.card-with-stepper {
    background: #ffffff;

    &__header {
        background-image: url('../../../assets/images/contract-bg.png');
        background-position: center;
        background-size: cover;

        &--alt-bg {
            background-image: url('../../../assets/images/contract-alt-bg.png');
        }
    }

    &__info {
        line-height: 24px;
    }

    &__desc {
        margin-right: 5px;
        color: rgba(32, 69, 105, 0.5);
    }

    &__status {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background: #ffd058;
        border-radius: 50%;

        &--red {
            background: #ff6b6b;
        }
    }

    &__status-name {
        margin-left: 10px;
        font-size: 12px;
        line-height: 24px;
    }

    &__msg {
        position: relative;
        margin-bottom: 5px;
        padding: 10px 20px;
        width: fit-content;
        color: rgba(32, 69, 105, 0.7);
        background: #f4f4f4;
        border-radius: 10px;

        &:after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: -1px;
            transform: rotate(10deg);
            border-style: solid;
            border-width: 15px 17px 0 0;
            border-color: #f4f4f4 transparent transparent transparent;
        }
    }

    &__box {
        display: flex;
    }
    label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .download-btn {
        min-width: 280px;
        text-transform: none;
        .download-link {
            color: #ffffff !important;
            text-decoration: none;
        }

        .MuiButton-label {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
            }
        }
    }

    &__upload-wrap {
        display: flex;
        background: #f4f4f4;
    }

    .upload-file {
        display: flex;
        margin: auto;
        padding-top: 15px;

        &__inner {
            margin-top: 18px;
        }

        &__title {
            margin-bottom: 10px;
            font-size: 18px;
            color: #046eec;
        }

        &__desc {
            color: rgba(34, 30, 31, 0.75);
        }
    }
}

.stepper-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;

    &:before {
        content: '';
        position: absolute;
        top: 25px;
        width: 100%;
        height: 1px;
        background: rgba(32, 69, 105, 0.1);
    }

    &__icon-wrap {
        position: relative;
        display: flex;
        margin-bottom: 10px;
        width: 48px;
        height: 48px;
        background: #f4f4f4;
        border-radius: 50%;

        svg {
            margin: auto;
            max-width: 24px;
            max-height: 24px;

            path {
                fill: #bccad4;
            }
        }

        &--active {
            background: #30d2ab;

            svg path {
                fill: #ffffff;
            }
        }

        &--with-badge {
            background: #ffd058;

            svg path {
                fill: #ffffff;
            }
        }
    }

    &__name {
        font-size: 12px;
        line-height: 24px;
    }

    &__badge {
        position: absolute;
        top: -20px;
        right: -42px;
        transform: rotate(10deg);
        padding: 5px 10px;
        min-width: 40px;
        text-align: center;
        font-size: 13px;
        line-height: 18px;
        color: #ffffff;
        background: #3796f6;
        border-radius: 8px;
    }
}
