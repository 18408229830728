.default-dialog-root {
  .paper-custom-dialog {
    min-width: 720px;
    color: inherit;
    border-radius: 6px;
    box-shadow: none;
  }

  .dialog-close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    background: transparent;
    transition: 0.3s linear;

    path {
      transition: .3s;
    }

    &:hover {
      path {
        opacity: 1;
        fill: #FF6B6B;
      }
    }
  }
}

.dialog-wrapper {
  position: relative;
  overflow: auto;
  padding: 50px 65px;
  max-height: 850px;
  background-color: #FFFFFF;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #EDF2F8;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: grey;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
  }

  p {
    line-height: 22px;
  }
}
