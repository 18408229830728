.custom-input-wrapper {
  &.readonly {
    .custom-input {
      background-color: rgba(235, 243, 250, .3);
    }

    input {
      cursor: default;
    }
  }

  .custom-input-label {
    font-family: inherit;
    font-size: 10px;
    line-height: 13px;
    font-weight: 600;
    color: inherit;
    transform: translate(15px, 17px);
    transition: 0.1s linear;

    &.custom-input-label-focused,
    &.custom-input-label-active {
      transform: translate(0, -20px);
      color: inherit;
    }

    &.custom-input-label-error {
      color: inherit;
    }
  }

  .custom-input {
    font-family: inherit;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: inherit;
    background-color: #FFFFFF;
    border: 1px solid rgba(32, 69, 105, 0.3);
    border-radius: 4px;
    transition: .3s linear;

    &:hover {
      border-color: rgba(32, 69, 105, 0.3);
    }

    input, textarea:not([aria-hidden]) {
      box-sizing: border-box;

      &::placeholder {
        color: #204569;
        opacity: .5;
      }
    }

    input {
      padding: 15px;
      height: 48px;
    }

    textarea:not([aria-hidden]) {
      min-height: calc(100px - 39px);
    }

    &.custom-input-focused {
      border-color: #204569;
    }

    &.custom-input-error, &.custom-input-error:hover {
      border-color: #E44B4B;
    }

    .custom-input-outline {
      display: none;
    }
  }

  .custom-input-disabled {
    background: #F2F3FC;
    border-color: #F2F3FC;
    cursor: not-allowed;

    &:hover {
      border-color: #F2F3FC;
    }
  }

  .error-text {
    position: absolute;
    bottom: -17px;
    left: 0;
    display: block;
    font-family: inherit;
    font-size: 10px;
    line-height: 13px;
    font-weight: 600;
    color: #E44B4B;
  }

  .symbol {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
}
