.contract-page {
  &__section {
    background: #FFFFFF;
    box-shadow: 0 4px 16px rgba(154, 166, 178, .15);
  }

  &__info-wrap {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 40px;
    column-gap: 105px;
  }

  &__info-item {
    color: rgba(10, 37, 64, .7);
  }

  &__submit {
    min-width: 240px;
  }

  &__submit {
    &.default-button {
      min-width: 240px;
    }
  }
}
