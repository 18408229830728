.snackbar-mui {
  .alert-mui {
    font-family: inherit;

    &.MuiAlert-filledError {
      background-color: #E44B4B;
    }

    .MuiAlert-message {
      font-family: inherit;
    }
  }
}
