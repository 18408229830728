.product-page {
  &__cat-nav {
    background: #FFFFFF;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
  }

  &__title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 800;
    color: #0A2540;
  }

  &__back {
    margin-right: 15px;
    width: 24px;
    height: 24px;
  }

  &__img {
    width: 68%;
    height: 440px;
    object-fit: cover;
    background: #FFFFFF;
  }

  &__desc {
    background: #FFFFFF;

    h2 {
      font-size: 16px;
      line-height: 24px;

      &:not(:first-of-type) {
        margin-top: 50px;
      }
    }

    p, h2 {
      margin-bottom: 25px;
      max-width: 880px;
    }

    .img-row {
      margin: 50px 0 50px;
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
    }
  }
}

.product-page-info {
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  width: 29%;
  background: #FFFFFF;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, .03);

  &__title {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 24px;
    color: #0A2540;
  }

  &__desc {
    margin-right: 5px;
    font-size: 13px;
    line-height: 24px;
    color: #204569;
    opacity: 0.5;
  }

  &__item {
    margin-bottom: 10px;
  }

  &__sep {
    margin: 15px 0;
    opacity: 0.25;
    border-top: 1px dashed #204569;
  }

  &__doc-btn {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 24px;
    color: #3796F6;

    &:not(:first-of-type) {
      margin-top: 15px;
    }

    svg {
      margin-right: 5px;
    }
  }

  &__time {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;

    &--desc {
      margin-left: 10px;
      margin-right: 5px;
      color: #204569;
      opacity: 0.5;
    }
  }

  &__btn-group {
    margin-top: auto;

    &--supplier {
      display: flex;
      justify-content: space-between;

      .default-button {
        min-width: 165px;
      }
    }
  }
}
