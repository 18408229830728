.cat-nav {
  display: flex;
  align-items: center;
  min-height: 70px;

  &__link {
    padding: 20px;
    color: #204569;
    opacity: 0.5;

    &:first-of-type {
      padding-left: 0;
    }

    &:hover, &.active {
      color: #0A2540;
      opacity: 1;
    }
  }
}
