@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-Heavy.eot');
  src: local('Mont Heavy'), local('Mont-Heavy'),
  url('../fonts/Mont-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mont-Heavy.woff2') format('woff2'),
  url('../fonts/Mont-Heavy.woff') format('woff'),
  url('../fonts/Mont-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-SemiBold.eot');
  src: local('Mont SemiBold'), local('Mont-SemiBold'),
  url('../fonts/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mont-SemiBold.woff2') format('woff2'),
  url('../fonts/Mont-SemiBold.woff') format('woff'),
  url('../fonts/Mont-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext');

@import './reset';
@import './base';
@import './variables';
