body {
  font-family: 'Mont', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #204569;
  background: #ffffff;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(132, 148, 185, 0.3);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(132, 148, 185, 0.3);
  }
}

a {
  color: #3796f6;

  &:hover {
    color: lighten(#3796f6, 10%);
  }
}

.app-wrap {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

.page-wrap {
  flex: 1;
  background: #ebf3fa;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1390px;
  width: 100%;
}

.h1 {
  font-size: 32px;
  line-height: 41px;
  font-weight: 600;
  letter-spacing: -1px;
}

.h2, .h3 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 800;
  color: #0A2540;
}

.h3 {
  font-size: 18px;
}

.backlink {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #3796F6;

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-right: 8px;
  }
}

.good {
  color: #2CC197;
}

.alert {
  color: #E44B4B;
}

.good-hover {
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.card-hover {
  cursor: pointer;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;

  &:hover {
    margin-top: -3px;
    margin-bottom: 3px;
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Pulse animation
.pulse {
  &:hover {
    animation: pulse 2s infinite;
  }
}

@keyframes pulse {
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  0% {
    opacity: 1;
  }
}

.Toastify__toast--error {
  background: #e02020 !important;
  padding: 6px 16px !important;
  font-size: 0.875rem !important;
  height: 48px !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
  0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
}

.Toastify__toast-container {
  width: 450px !important;
}
