.home-page {
  &__cat-nav {
    background: #FFFFFF;
  }

  &__products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
    column-gap: 15px;
    row-gap: 30px;
  }

  &__not-found {
    transition: .5s;
  }
}
