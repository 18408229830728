.loader-mui {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 5;

  &--bg {
    background: rgba(0, 0, 0, 0.5);
  }

  &__linear {
    &.loader-root {
      height: 2px;
    }

    &.loader-color {
      background-color: rgba(55, 150, 246, 0.2);
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: #3796F6;
    }
  }

  &__circular {
    margin: auto;
  }
}
