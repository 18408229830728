.pagination-container {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #FFFFFF;

  &__msg {
    margin-left: 30px;
    font-size: 12px;
    line-height: 15px;
    color: rgba(32, 69, 105, .5);
  }
}

.pagination-list {
  display: flex;

  li {
    border-radius: 2px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease .3s;

    &:hover {
      background: rgba(132, 148, 185, 0.1);
    }

    a {
      font-family: inherit;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 5px;
      box-sizing: border-box;
    }
  }
}

.pagination-link {
  cursor: pointer;
  background: transparent;
  color: #3796F6;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  height: 24px;
  min-width: 24px;
  font-size: 14px;

  &.pagination-link-active {
    border-radius: 2px;
    background: #3796F6;
    color: #ffffff;

    &:hover {
      opacity: 0.7;
    }
  }
}

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  a {
    cursor: pointer;
    background: transparent;
    height: 24px;
    width: 24px;
    color: #3796F6;
    text-decoration: none;
    text-align: center;
    line-height: 28px;
  }
}

.next, .previous {
  margin: 0;

  &.disabled {
    &:hover {
      background: transparent;
    }

    a {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  a {
    display: flex;

    svg {
      margin: auto;
      font-size: 20px;
      fill: #3796F6;
    }
  }
}
