.supplies-page {
  &__msg {
    font-size: 13px;
    background: #FFFFFF;
  }

  &__msg-container {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
