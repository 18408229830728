.footer {
  margin-top: auto;
  border-top: 1px solid rgba(184, 200, 214, 0.5);

  &__container {
    display: flex;
    align-items: center;
    min-height: 75px;
  }

  &__link {
    padding: 20px;
    color: #204569;
    opacity: 0.5;

    &:hover, &.active {
      color: #204569;
      opacity: 1;
    }
  }

  &__info {
    margin-left: auto;
    margin-right: 30px;
  }

  &__socials {
    display: flex;
    column-gap: 30px;
  }
}

.social-item {
  width: 28px;
  height: 28px;

  &__link {
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }

  &__icon {
    max-width: 28px;
    max-height: 28px;
  }
}
