:root {
  --color: #3796F6;

  /* Mixins */
  $sizes: 100;
  $mediumSizes: 300;
  $bigSizes: 2000;
  $unit: 1px;

  // For margin & padding
  @mixin generateIndentClass($prefix, $property) {
    @for $i from 1 through $mediumSizes {
      .#{$prefix}-#{$i} {
        #{$property}: $i * $unit;
      }
    }
  }

  $rules: [
    [m, margin],
    [ml, margin-left],
    [mt, margin-top],
    [mb, margin-bottom],
    [mr, margin-right],

    [p, padding],
    [pl, padding-left],
    [pt, padding-top],
    [pb, padding-bottom],
    [pr, padding-right],
  ];

  @each $item in $rules {
    @include generateIndentClass(nth($item, 1), nth($item, 2));
  }

  // For margin & padding - X/Y
  @mixin indentXYClasses {
    @for $i from 1 through $mediumSizes {
      $value: $i * $unit;
      .mx-#{$i} {
        margin-left: $value;
        margin-right: $value;
      }
      .my-#{$i} {
        margin-top: $value;
        margin-bottom: $value;
      }
      .px-#{$i} {
        padding-left: $value;
        padding-right: $value;
      }
      .py-#{$i} {
        padding-top: $value;
        padding-bottom: $value;
      }
    }
  }

  @include indentXYClasses;

  // For width
  @mixin width {
    // width in percents
    @for $i from 1 through $sizes {
      .w-#{$i} {
        width: $i * 1%;
      }
    }

    // min/max-width in px
    @for $i from 1 through $bigSizes {
      $value: $i * $unit;
      .min-w-#{$i} {
        min-width: $value;
      }
      .max-w-#{$i} {
        max-width: $value;
      }
    }
  }

  @include width;

  // For height
  @mixin height {
    // height in percents
    @for $i from 1 through $sizes {
      .h-#{$i} {
        height: $i * 1%;
      }
    }

    // min/max-height in px
    @for $i from 1 through $bigSizes {
      $value: $i * $unit;
      .min-h-#{$i} {
        min-height: $value;
      }
      .max-h-#{$i} {
        max-height: $value;
      }
    }
  }

  @include height;

  // Helper classes
  .m-auto {
    margin: auto;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .t-center {
    text-align: center;
  }
}

// For breakpoints
@mixin breakpoint($min-width, $max-width: false, $orientation: false) {
  @if $orientation {
    @if $max-width {
      @media only screen and (min-width: #{$min-width}px) and (max-width: #{$max-width}px) and (orientation: $orientation) {
        @content;
      }
    } @else {
      @media only screen and (min-width: #{$min-width}px) and (orientation: $orientation) {
        @content;
      }
    }
  } @else {
    @if $max-width {
      @media only screen and (min-width: #{$min-width}px) and (max-width: #{$max-width}px) {
        @content;
      }
    } @else {
      @media only screen and (min-width: #{$min-width}px) {
        @content;
      }
    }
  }
}
