.offer-product {
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 350px;
    background: #FFFFFF;
    clip-path: ellipse(55% 100% at 50% 0%);
  }

  &__icon {
    position: absolute;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0 4px 16px rgba(154, 166, 178, 0.15);
  }

  &__field-group {
    display: flex;
    gap: 15px;
  }

  &__select {
    position: relative;
  }

  &__select-label {
    position: absolute;
    top: -20px;
    font-size: 10px;
    line-height: 13px;
  }
}
